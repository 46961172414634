import React from "react"
import "./generic-hero.module.scss"

import { HeroContent } from "components/hero/HeroContent"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"

import { Banner } from "../_types/hero-types"
import {
  GenerateVariationPrefixes,
  HeroContentBaseClassName,
} from "../_types/hero-classes-types"

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const { background_image, heading, sub_heading, secondary_image } = banner

  const prefix: GenerateVariationPrefixes = "fr-generic-hero",
    secondaryImage = secondary_image?.value[0],
    backgroundImageUrl = background_image?.value[0]?.url,
    hasBackgroundImage = !!backgroundImageUrl,
    wrapperClassName = hasBackgroundImage
      ? `${prefix}--with-background-image`
      : `${prefix}--with-default-background-color`,
    bannerContentBaseClassName = hasBackgroundImage
      ? (`${prefix}__banner-content--with-image` as HeroContentBaseClassName)
      : (`${prefix}__banner-content` as HeroContentBaseClassName)

  return (
    <BannerWrapper
      contentClassName={bannerContentBaseClassName}
      prefix={prefix}
      wrapperClassName={wrapperClassName}
      containerSize="large"
      backgroundImageUrl={backgroundImageUrl}
    >
      <HeroContent
        baseClassName={prefix}
        heading={heading?.value}
        subHeading={sub_heading}
        secondaryImage={secondaryImage}
      />
    </BannerWrapper>
  )
}
