import React, { useRef, useState } from "react"
import { SwiperSlider } from "./Slider"
import { Swiper as SwiperClass } from "swiper"
import { SwiperSlide } from "swiper/react"

import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { HeroTab } from "./TabbedHero-types"
import { HeroContent } from "../HeroContent"

import "./tabbed-hero.module.scss"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
  hasSecondaryImage: boolean
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  hasSecondaryImage,
}: TabbedHeroBannerContentProps) => {
  const tabsRef = useRef<HTMLDivElement>(null)
  const { allContent, handleChangeActiveTab } = useTabbedContainer<HeroTab>()

  const [swiper, setSwiper] = useState<SwiperClass>()

  const slideTo = (index: number) => {
    swiper?.slideTo(index, 100)
  }

  return (
    <>
      <SwiperSlider
        className={`fr-tabbed-hero-slider`}
        onSwiper={setSwiper}
        onActiveIndexChange={swiper =>
          handleChangeActiveTab(swiper.activeIndex)
        }
      >
        <div
          ref={tabsRef}
          slot="container-start"
          className="fr-tabbed-hero-slider__tabs-wrapper"
        >
          {allContent.map(({ elements, id }, i) => {
            if (!elements) return
            const { tab_title } = elements

            return (
              <button
                className={`button--hero-tab fr-tabbed-hero-slider__tab ${
                  swiper?.activeIndex === i ? "button--hero-tab--active" : ""
                }`}
                key={id}
                onClick={() => slideTo(i)}
              >
                <span>{tab_title?.value}</span>
              </button>
            )
          })}
        </div>

        {allContent.map(({ elements }, i) => {
          if (!elements) return

          const { buttons, heading, sub_heading, tagline } = elements

          return (
            <SwiperSlide key={i} virtualIndex={i}>
              <HeroContent
                baseClassName={"fr-hero"}
                buttons={buttons?.value}
                hasAlwaysOfficeNumber
                heading={heading?.value}
                subHeading={sub_heading}
                tagline={tagline}
              />
            </SwiperSlide>
          )
        })}
      </SwiperSlider>
    </>
  )
}
