import React from "react"
import { graphql } from "gatsby"
import { FormStub } from "./FormStub"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import { useLayout } from "contexts/components/LayoutContext"
import "./form-stub.module.scss"

interface StubProps {
  formStub: KontentFormStubBannerFormatted
}

export const StubBanner = ({ formStub }: StubProps) => {
  const { buttons, heading, subheading } = formStub
  const { isContactPage, isHomepage } = useLayout()

  if (isContactPage) return <></>

  const isInteriorPage = !isHomepage
  const interiorPageModifier = isInteriorPage
    ? "form-stub-banner--internal-page"
    : ""

  return (
    <section className={`form-stub-banner ${interiorPageModifier}`}>
      <div className="fr-container">
        <div className="form-stub-banner__container">
          <div className="u-container-header form-stub-banner__heading-container">
            <h2 className="form-stub-banner__heading">{heading}</h2>
            {isHomepage && (
              <p className="form-stub-banner__subheading">{subheading}</p>
            )}
          </div>
          <FormStub buttons={buttons} />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
