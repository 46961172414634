import React, { useCallback } from "react"
import { graphql } from "gatsby"
import "components/hero/HeroBanner/hero.module.scss"
import "./tabbed-hero-banner.module.scss"

import { HeroContent } from "../HeroContent"
import { HeroTab } from "./TabbedHero-types"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { HeroContentBaseClassName } from "../_types/hero-classes-types"
import { BackgroundImage } from "./BackgroundImage"
import { Banner } from "../_types/hero-types"

interface TabbedHeroBannerProps {
  banner: Banner & {
    hero_tabs: {
      modular_content: HeroTab[]
    }
  }
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { background_image, hero_tabs } = banner

  const wrapperClassName = `fr-tabbed-hero`

  const bannerContentBaseClassName =
    `fr-hero__banner-content` as HeroContentBaseClassName

  const contentClassName = bannerContentBaseClassName

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const { heading, sub_heading, buttons, tagline } =
        activeTabContent?.elements

      return (
        <HeroContent
          baseClassName="fr-hero"
          buttons={buttons?.value}
          heading={heading?.value}
          subHeading={sub_heading}
          tagline={tagline}
        />
      )
    },
    [contentClassName]
  )

  const handleGetHeroBgImageUrl = useCallback(
    (activeTabContent?: HeroTab): Record<string, string> => {
      if (!activeTabContent) return {}

      const backgroundImageUrl =
        activeTabContent?.elements?.background_image_override?.value[0]?.url ||
        background_image?.value[0]?.url

      const backgroundImageCSSVariable = useBackgroundImage({
        backgroundImageUrl,
        variable: "hero",
      })

      return backgroundImageCSSVariable
    },
    [background_image]
  )

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <BannerWrapper
        contentClassName={contentClassName}
        prefix="fr-hero"
        wrapperClassName={wrapperClassName}
        backgroundImageComponent={
          <BackgroundImage
            prefix="fr-tabbed-hero"
            onHandleGetHeroBgImageUrl={handleGetHeroBgImageUrl}
          />
        }
      >
        <TabbedHeroBannerContent
          contentClassName={contentClassName}
          handleActiveTabContent={getActiveTabContent}
          hasSecondaryImage={false}
        />
      </BannerWrapper>
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
        type
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components_override {
        modular_content {
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
