import React from "react"
import "./form-stub.module.scss"
import { FormStubField } from "./formStubBanner-types"
import { ButtonParser } from "components/button/ButtonParser"
import { ButtonBuilder } from "_types/Button"

interface FormStubProps {
  buttons: ButtonBuilder[]
  fields?: FormStubField[]
}

export const FormStub = ({ fields, buttons }: FormStubProps) => {
  return (
    <div className="form-stub">
      <div className="form-stub__field-container">
        {buttons.length > 0 && (
          <ButtonParser
            buttons={buttons}
            ctaColorVariantSolid="solid-secondary"
            extraClassNames="form-stub__button"
          />
        )}
      </div>
    </div>
  )
}
